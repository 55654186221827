import Vue from 'vue'
import FloatingCart from "./FloatingCart.vue";
import EventEmitterMixin from 'frappe/public/js/frappe/event_emitter';

frappe.provide("ioi_partners.floating_shopping_cart_update")

function ioi_format_currency(v, currency, decimals) {
	const format = get_number_format(currency);
	const symbol = get_currency_symbol(currency);

	if (decimals === undefined) {
		decimals = frappe.boot.sysdefaults.currency_precision || null;
	}

	return format_number(v, format, decimals) + " " + __(symbol);
}


Vue.prototype.__ = window.__;
Vue.prototype.frappe = window.frappe;
Vue.prototype.ioi_format_currency = ioi_format_currency;
Vue.prototype.frappe.boot.sysdefaults = {}
Vue.prototype.frappe.boot.sysdefaults.currency = "EUR"
window.Vue = Vue;
window.ioi_format_currency = ioi_format_currency;

ioi_partners.floating_shopping_cart_update = {}

ioi_partners.floating_shopping_cart = () => {
	Object.assign(ioi_partners.floating_shopping_cart_update, EventEmitterMixin);

	new Vue({
		el: "#floating-cart",
		render: (h) => h(FloatingCart, {}),
	});
}