frappe.provide("ioi_partners")
ioi_partners.ProductList = class {
	/* Options:
		- items: Items
		- products_section: Products Wrapper
	*/
	constructor(options) {
		Object.assign(this, options);

		this.products_section.empty();
		this.make();
	}

	make() {
		let me = this;
		let html = `<br><br>`;

		this.items.forEach(item => {
			let title = item.product_name || item.name || "";
			title =  title.length > 200 ? title.substr(0, 200) + "..." : title;

			html += `<div class='row list-row w-100 mb-4' data-item='${item.name}'>`;
			html += me.get_image_html(item, title);
			html += me.get_row_body_html(item, title);
			html += `</div>`;
		});

		let $product_wrapper = this.products_section;
		$product_wrapper.append(html);

		this.items.forEach(item => {
			this.bind_add_remove_qty(item);
		})
	}

	get_image_html(item, title) {
		let image = item.image;
		let image_html = ``;

		if (image) {
			image_html += `
				<div class="col-2 border text-center rounded list-image">
					<div class="product-link product-list-link">
						<img itemprop="image" class="website-image h-100 w-100" alt="${ title }"
							src="${ image }">
					</div>
				</div>
			`;
		} else {
			image_html += `
				<div class="col-2 border text-center rounded list-image">
					<div class="product-link product-list-link"
						style="text-decoration: none">
						<div class="card-img-top no-image-list">
							${ frappe.get_abbr(title) }
						</div>
					</div>
				</div>
			`;
		}

		return image_html;
	}

	get_row_body_html(item, title) {
		let body_html = `<div class='col-10 text-left'>`;
		body_html += this.get_title_html(item, title);
		body_html += this.get_item_details(item);
		body_html += `</div>`;
		return body_html;
	}

	get_title_html(item, title) {
		let title_html = `<div style="display: flex; margin-left: -15px;">`;
		title_html += `
			<div class="col-8">
				<div style="color: var(--gray-800); font-weight: 500;">
					${ title }
				</div>
			</div>
		`;

		title_html += `<div class="col-4 cart-action-container">`;

		if (!item.cannot_be_selected) {
			if (item.in_cart && ["User", "Credit"].includes(item.unit_of_measure)) {
				title_html += this.get_quantity_picker(item);
			} else {
				title_html += this.get_primary_button(item);
			}
		}
	
		title_html += `</div>`;
		title_html += `</div>`;

		return title_html;
	}

	get_item_details(item) {
		let details = `
			<div class="row no-gutters">
			<div class="col-12 col-md-8">
			<div class="mt-2" style="color: var(--gray-800) !important; font-size: 14px;">
				${ item.description || '' }
			</div>
			<div class="mt-2" style="color: var(--gray-600) !important; font-size: 12px;">
		`;

		if (!item.available_in_eval) {
			details += `<div>${__("Not available in evaluation")}</div>`
		} else if (item.eval_replaced_by) {
			details += `<div>${__("Will be replaced if evaluation by :")} ${item.eval_replaced_by}</div>`
		}

		if (item.estimated_setup_time && item.estimated_setup_time != 0) {
			details += `<div>${__("Estimated configuration time by your reseller")}: ${ item.estimated_setup_time } ${__("hours")}</div>`
		}

		if (item.install_cost && item.install_cost != 0) {
			details += `<div>${__("Install cost")}: ${ item.formatted_install_cost }</div>`
		}

		details += `
			</div>
			</div>
		`;

		details += `
			<div class="col-12 col-md-4 product-price-section" style="padding-right: 15px;">
			<div class="product-price">
				<span>${ item.formatted_price || '' }</span>
				<span class="pricing-unit">${ __(item.pricing_unit) || "" }</span>
			</div>
		`;

		if (item.footer_note) {
			details += `<div class="text-muted footer-note">${ __(item.footer_note) }</div>`;
		}

		if (!item.cannot_be_selected && (item.in_cart && ["User", "Credit"].includes(item.unit_of_measure))) {
			details += `<div class="mt-2">${this.get_primary_button(item)}</div>`;
		}

		details += `
			</div>
			<p class="product-code">
				${ __(item.product_family) } | ${ __("Product Code") } : ${ item.name }
			</p>
			</div>
		`;

		return details;
	}

	get_primary_button(item) {
		return `
			<div id="${ item.name }" class="btn
				btn-sm btn-primary btn-add-to-cart-list mb-0 ${item.in_cart ? 'btn-go-to-cart-list' :''}"
				data-item-code="${ item.name }" data-selection-option="${ item.selection_option }" data-product-family="${ item.product_family }">
				<span class="cart-indicator ${item.in_cart ? '' :'hidden'}">
					${frappe.utils.icon('check', 'xs')}
				</span>
				<span class="btn-add-to-cart-list-icon ${item.in_cart ? 'hidden' :''}">
					<svg class="icon icon-md">
						<use href="#icon-assets"></use>
					</svg>
				</span>
				<span class="add-to-cart-btn-text ${item.in_cart ? 'hidden' : 'd-none d-sm-inline'}">${ __('Add to Cart') }</span>
				<span class="go-to-cart ${item.in_cart ? 'd-none d-sm-inline' : 'hidden'}">${["User", "Credit"].includes(item.unit_of_measure) ? item.total_in_cart + " " + __("in basket") : __("See selection") }</span>
			</div>
		`;
	}

	get_quantity_picker(item) {
		const picker = `<div class="flex">
				<div class="qty-minus grey-btn"><span>-</span></div>
				<div class="qty">${item.total_in_cart} ${__(item.unit_of_measure)}</div>
				<div class="qty-plus grey-btn"><span>+</span></div>
			</div>`

		this.bind_add_remove_qty(item)

		return picker
	}

	bind_add_remove_qty(item) {
		$(`#product-listing [data-item='${item.name}']`).find(".qty-minus").click(e => {
			this.product_listing.remove_qty_from_subscription(item.name)
		})

		$(`#product-listing [data-item='${item.name}']`).find(".qty-plus").click(() => {
			this.product_listing.add_qty_to_subscription(item.name)
		})
	}

};