import Vue from 'vue'
import CartPage from "./CartPage.vue";

function ioi_format_currency(v, currency, decimals) {
	const format = get_number_format(currency);
	const symbol = get_currency_symbol(currency);

	if (decimals === undefined) {
		decimals = frappe.boot.sysdefaults.currency_precision || null;
	}

	return format_number(v, format, decimals) + " " + __(symbol);
}


Vue.prototype.__ = window.__;
Vue.prototype.frappe = window.frappe;
Vue.prototype.ioi_format_currency = ioi_format_currency;
Vue.prototype.frappe.boot.sysdefaults = {}
Vue.prototype.frappe.boot.sysdefaults.currency = "EUR"
window.Vue = Vue;
window.ioi_format_currency = ioi_format_currency;

ioi_partners.shopping_cart = (subscription) => {
	new Vue({
		el: "#cart",
		render: (h) => h(CartPage, {
			props: {
				subscription_name: subscription
			}
		}),
	});
}

$("body").find(".container").addClass("container-fluid").removeClass("container")